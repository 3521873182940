import * as yup from 'yup'
import { name } from './name'

export const theme = name.shape({
  name: yup.string().default(''),
  notes: yup.array().of(yup.string().required()),
  documents: yup.array().of(yup.string().required()),
  highlightreels: yup.array().of(yup.string().required()),
  clips: yup.array().of(yup.string().required()),
  project: yup.string(),
  themeBoard: yup.string(),
})
export type themeType = yup.InferType<typeof theme>

export const arrayOfThemes = yup.object().shape({
  themes: yup.array().of(yup.string().required()),
})

export type arrayOfThemesType = yup.InferType<typeof arrayOfThemes>
